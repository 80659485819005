.control-panel {
    margin: 16px 20px;
    /*height: 58px;*/
    padding: 6px 16px;
    justify-content: space-between;
}

.justified {
    justify-content: space-between;
}

.label {
    text-transform: uppercase;
    font-size: 14px;
}

.view-mode__label {
    line-height: 1.1;
    max-width: 48px;
}