.grid-artwork {
    padding: 16px;
}

.grid-artwork .title {
    font-size: 14px;
    color: #e5eaee;
}

.grid-artwork .title-block {
    margin-bottom: 12px;
}

.grid-artwork .subtitle {
    font-size: 13px;
    color: #858585;
}

.time-block {
    font-size: 12px;
    color: #858585;
}

.state-block svg {
    margin-left: 8px;
}

.state-block {
    margin: 12px 0;
    font-size: 14px;
    color: #e5eaee;
}

.grid-artwork .comments {
    min-height: 100px;
    display: flex;
}

.comments .empty-comments {
    font-size: 14px;
    align-self: center;
    width: 100%;
    text-align: center;
}

.comments .comment-author {
    color: #e5eaee;
    font-size: 14px;
}

.comments .comment-text {
    color: #858585;
    font-size: 12px;
}