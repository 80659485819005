.login-form {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;
}

.login-form form {
  padding: 40px 40px 20px 40px;
}

.login-error {
  color: #ff1744;
  text-align: center;
}

.login-success {
  color: #e5eaee;
  text-align: center;
}

.login-button {
  margin-top: 40px;
}

.logo {
  width: 100px;
  display: block;
  margin: 120px auto 0 auto;
}

/* Change the white to any color ;) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 300px white inset !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: white !important;
  color: rgb(0, 0, 0);
}

.reset-page {
  margin: 0 auto 0 auto;
}

.auth__link {
  font-size: 12px;
  font-weight: 400;
  margin-top: -50px;
}
