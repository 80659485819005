

.artworkBox .artworkBoxImage {
  display: flex;
  width: 205px;
  height: 205px;
  margin-right: 10px;
  border: 1px solid #858585;
  align-items: center;
  overflow: hidden;
}
.artworkBoxImage img {
  max-width: 100%;
}
.artworkBox .repeater-remove {
  position: absolute !important;
  top: 0;
  right: 0;
}

.Exhibition-controls .controls {
  padding: 0 30px;
}
.Exhibition-controls {
  position: relative;
}
.Exhibition-controls:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #222222;
  border-bottom: 1px solid #494949;
}

.Exhibition-controls .controls .row {
  padding-left: 30px;
  padding-bottom: 15px;
}

.padded-left {
  padding-left: 16px;
}

.searchable-modal h2 {
  font-weight: 500;
  text-align: center;
}

.searchable-modal table th {
  color: #999999;
  font-weight: 300;
}

.rich-text-editor > * {
  color: #212121;
}
