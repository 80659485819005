.section-inside {
  padding-left: 20px;
}

.section-inside h1 {
  margin-left: -20px;
  font-size: 28px;
  margin-bottom: 10px;
}
.modal.modal-open.exhibition-modal {
  width: 100%;
  max-width: 1400px;
}
.select-exhibition {
  width: 100%;
}
.select-exhibition .react-select__control {
  width: 100% !important;
}
.select-exhibition .react-select__menu {
  position: relative !important;
}

input[type='date']::-webkit-inner-spin-button {
  display: none;
}
