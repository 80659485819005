.geosuggest input {
    padding: 14px 16px;
    font-weight: 500 !important;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    width: 100%;
}

.geosuggest {
    position: relative;
}

.geosuggest__suggests--hidden {
    display: none;
}
.coords {
    margin:4px 0;
}
.geosuggest__suggests {
    list-style: none;
    padding: 0;
    margin: 0;
}

.geosuggest__item {
    padding: 12px 16px;
    cursor: pointer;
}

.geosuggest__item--active {
    background: #efefef;
}

.geosuggest__item:hover {
    background: #efefef;
}

.geosuggest__suggests-wrapper {
    position: absolute;
    z-index: 999;
    width: 100%;
    background: #fff;
    color: #858585;
}