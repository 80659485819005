.pagination {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
}

.page-button {
    background: #222222;
    width: 40px;
    height: 40px;
    color: #fff;
    text-align: center;
    border: none;
    cursor: pointer;
}

.page-button:hover {
    opacity: 0.8;
}

.page-button.current {
    color: #fff;
}