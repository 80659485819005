.page {
  margin: 20px 0 80px 0;
}

.page .field {
  margin-top: 20px;
}

.page .field--top {
  margin-top: 0;
}

.page .align-left-label {
  margin: 0 0 5px 0;
}

.page .image-container {
  margin-right: 40px;
}

.page .remove-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: flex-start;
  height: 100%;
}

.page .image-container p {
  text-align: center;
  padding: 10px;
}

.page .editor--transcription {
  background-color: #fff;
  color: #323232;
}

.editor__editor {
  padding: 0 10px;
}

.editor__toolbar label {
  color: #323232 !important;
}

.editor--transcription .rdw-embedded-modal,
.editor--transcription .rdw-link-modal {
  height: 235px;
}

.editor__btn--double-underline {
  font-family: serif;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-style: double;
}

.double-underline {
  text-decoration: underline;
  text-decoration-style: double;
}

.editor__editor a,
.editor__editor a span {
  color: #126af8 !important;
}

.editor__mention span {
  color: #54cb21 !important;
}

.editor__comment-container {
  position: relative;
}

.editor__comment {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  padding: 5px;
  height: 48px !important;
  width: auto;
  top: 100%;
  /* position: absolute; */
  /* top: 0;
  right: 10%; */
  z-index: 99999999;
}

.editor__comment input {
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  height: 36px;
  padding: 5px;
}

.editor__icon {
  position: relative;
}

.editor__icon .MuiSvgIcon-root-1 {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -50%;
  left: -6px;
}

.editor__commented {
  cursor: pointer;
  background: #e4f3f0;
}

.react-select__option--image {
  display: flex;
  align-items: center;
}

.react-select__single-value--image {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 2px 8px;
  visibility: visible;
}

.select__option-image {
  width: 60px;
  margin: 5px 20px 5px 10px;
}

.select_value_image {
  max-width: 40px;
  margin-right: 20px;
  max-height: 40px;
}

.insert-mention-modal {
  height: 400px;
}

.insert-mention-modal .react-select__control {
  width: 100% !important;
}



.insert-mention-modal .react-select__menu {
  position: relative !important;
}

.react-select__control--is-focused.react-select__control--menu-is-open .react-select__single-value--image {
  visibility: hidden;
}
