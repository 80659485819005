.menu-list {
    padding: 0 !important;
}

.left-menu {
    position: fixed;
    left: 0;
    height: 100%;
    max-width: 250px;
    background: #2a2a2a;
}
