.Catalogue-form {
  padding: 0 60px;
}
.groupRadioButtons {
  display: flex;
  max-width: 460px;
}
.Catalogue-controls .controls {
  padding: 0 30px;
}
.Catalogue-controls {
  position: relative;
}
.Catalogue-controls:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #212121;
  border-bottom: 1px solid #494949;
}

.Catalogue-controls .controls .row {
  padding-left: 30px;
  padding-bottom: 15px;
}
.Catalogue-form .reference-exerpt {
  margin-left: 16px;
}
.padded-left {
  padding-left: 16px;
}

.searchable-modal h2 {
  font-weight: 500;
  text-align: center;
}

.searchable-modal table th {
  color: #999999;
  font-weight: 300;
}

.rich-text-editor > * {
  color: #212121;
}
