.form-top {
    padding: 0 16px;
    height: 40px;
    margin: 10px 0;
}

.form-top .left-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
}