.artwork-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.section-title {
  font-weight: 600;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}

.sub-section {
  font-weight: 500;
  margin-top: 25px;
  font-size: 22px;
  margin-bottom: 20px;
}

.table-img-holder {
  margin-right: 16px;
}

.related-item {
  cursor: pointer;
  margin-bottom: 6px;
  transition: all 0.3s ease;
}

.related-item:hover {
  background: #858585;
}

.id-check {
  margin: 8px 0;
}

.image-label {
  font-weight: 500;
  margin-bottom: 10px;
}

.image-info-label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.align-left-label,
.single-img p.align-left-label {
  text-align: left;
}

.repeater .white-btn {
  color: #fff !important;
}

.id-list-btn {
  align-self: flex-end;
  margin-bottom: 6px !important;
}

.repeater-field {
  margin-right: 16px !important;
}

.space-between {
  justify-content: space-between;
}

.reference-link {
  cursor: pointer;
  font-size: 14px;
  margin: 6px 16px;
}

.reference-holder {
  align-self: flex-end;
  display: inline-flex;
  height: 48px;
  align-items: center;
}

.single-radio {
  align-self: flex-end;
  display: inline-flex;
  height: 48px;
  align-items: center;
  justify-content: center;
}

.location-radio .radio-wrapper > div {
  width: auto !important;
  flex: 1;
  display: block !important;
  margin: 16px 16px;
}
.indent-1 {
  margin-left: 30px;
}
.indent-2 {
  margin-left: 60px;
}

.present-field {
  width: 60px;
}

.modal.modal-open {
  width: 680px;
  min-height: 300px;
  max-height: 100vh;
  max-height: 100%;
  overflow-y: auto;
}

input[type='text'] {
  width: 100%;
}

.single-img {
  margin: 10px;
}

.section-spacing {
  margin-bottom: 30px !important;
}

.single-img p {
  padding: 10px;
  text-align: center;
}

.img-holder img {
  max-width: 100%;
}

.img-holder button {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -24px !important;
  margin-left: -24px !important;
}

.hover-background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: #fff;
}

.hover-background:hover {
  opacity: 0.7;
}

.select-reference .reference-link {
  margin-top: 0;
  margin-bottom: 0;
}

.reference-exerpt {
  margin-left: 16px;
}

.tabs {
  margin: 10px 0 10px 0;
}

.tabs a {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  margin-right: 30px;
  padding: 0 5px 6px 5px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.controls {
  margin-top: 24px;
  padding: 0 60px;
}

.artwork-controls {
  position: sticky;
  top: 64px;
  left: 0;
  z-index: 1200;
  padding-bottom: 2px;
  background: #323232;
  width: 100%;
  overflow: hidden;
}

.repeater-remove {
  align-self: flex-end;
}

.section-inside {
  position: relative;
  padding: 20px 40px !important;
}

.section-inside:before {
  content: '';
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #464646;
  border-top: 1px solid #212121;
}

.top-title {
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px;
}

.top-title .title {
  text-transform: uppercase;
  font-size: 16px;
}

.top-title .item-left {
  /* width: 312px; */
  margin: 0 auto 0 0;
}

.title--edit-top {
  margin: 0 auto;
}

.hover-background {
  cursor: pointer;
}

.reDropzone {
  cursor: pointer;
  overflow: hidden;
  padding: 0 10px 0 10px;
}

.reDropzone p {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  letter-spacing: 0px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  color: #858585;
  margin: 0;
}

.reDropzone p:hover {
  opacity: 0.8;
}

.image-preview .image-label {
  font-size: 18px;
  font-weight: 600;
  color: #323232;
  margin-bottom: 0;
}

.img-holder.upload-loader {
  width: 43px;
  height: 43px;
  border: none;
  overflow: visible;
}
.modal.modal-open.reference-modal.photo-modal {
  background: none;
  text-align: center;
  width: auto;
  max-width: 1920px;
}
.image-preview {
  display: inline-block;
  padding: 30px;
  background-color: #e5e5e5;
  width: 1000px;
  max-width: 100%;
}
.image-preview {
  vertical-align: middle;
  position: relative;
}
.image-preview .left-right-block {
  margin-bottom: 30px;
  padding: 0 10px;
}
.image-preview .right-controls {
  justify-content: space-between;
}
.iconbutton-delete svg {
  fill: #6a6a6a;
  font-size: 36px;
}
.iconbutton-next {
  opacity: 0.7;
}
.iconbutton-next:hover {
  opacity: 1;
}
.hotspots-select {
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}
.hotspots-select p {
  width: 100%;
  text-align: left;
  margin-top: 0;
  font-size: 20px;
}
.hotspots-select .close {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
.hotspots-select img {
  object-fit: contain;
  cursor: crosshair;
  align-self: flex-start;
}
.hotspots-select ul,
li {
  list-style: none;
}
.hotspots-select li {
  position: relative;
  padding-right: 48px;
  margin-bottom: 20px;
}
.hotspots-select .hotspots {
  display: flex;
}
.gap-maker {
  opacity: 0;
  visibility: hidden;
  line-height: 0;
}

.keyword-select {
  z-index: 999 !important;
}

/* General styles for the select control */
.react-select__control {
  background-color: rgb(54, 54, 54) !important;
  border: 1px solid rgb(45, 45, 45) !important;
  border-radius: 0 !important;
  height: 48px;
  width: 256px;
  transition: all 0.2s ease;
}

.react-select__control--is-focused {
  background-color: #fff !important;
  box-shadow: none !important;
}

.react-select__control--is-focused .react-select__input {
  background-color: transparent !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__placeholder {
  color: #aaa !important;
}

.react-select__single-value {
  color: #e5eaee !important;
}

.react-select__control--is-focused .react-select__single-value {
  color: #111 !important;
}

.react-select__option {
  color: #858585 !important;
}

.react-select__option--is-focused,
.react-select__option:hover {
  color: #111 !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer;
}

.react-select__option--is-selected {
  color: #111 !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.custom-arrow-zone {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
}

/* Arrow inside the dropdown indicator */
.custom-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #999; /* Default arrow color */
  margin-left: 5px;
  margin-right: 5px;
  transition: border-top-color 0.2s ease; /* Smooth hover transition */
}

/* Hover effect for the arrow when its zone is hovered */
.custom-arrow-zone:hover .custom-arrow {
  border-top-color: #666; /* Arrow color changes on hover */
}

.react-select__menu {
  border-radius: 0 !important;
  margin-top: 0 !important;
  z-index: 100 !important;
}
