.artworks .grid {
    padding: 0 20px;
}

.table-img-holder {
    max-width: 60px;
}

.table-img-holder img {
    width: 100%;
    max-width: 100%;
}

.filter-section {
    margin: 0px 40px;
}

.filter-section .section-label {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.filters {
    padding-bottom: 20px;
}

.grid-artwork {
    cursor: pointer;
}