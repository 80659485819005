.header {
    margin-bottom: 64px;
}

.header .title {
    display: block;
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.header .subtitle {
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.2;
    letter-spacing: 1px;
    text-transform: uppercase;
    /* padding-left: 16px; */
}

.title-text {
    display: none;
}

.app-bar-logo {
    width: 40px;
    height: 40px;
    margin-right: 16px;
}

.app-bar-title {
    display: flex;
    align-items: center;
    padding: 12px 0;
}

[type="search"]::-webkit-input-placeholder {
    color: #fff;
}

[type="search"]:-moz-placeholder {
    /* FF 4-18 */
    color: #fff;
}

[type="search"]::-moz-placeholder {
    /* FF 19+ */
    color: #fff;
}

[type="search"]:-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
}

.user-menu button {
    padding: 0 !important;
}