.title-heading {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0 24px;
}

.search {
    position: sticky;
    width: 100%;
    top: 64px;
    left: 0;
    z-index: 99;
    background: #323232;
}

.search > div {
    min-height: 48px;
}