* {
  box-sizing: border-box;
  outline: none;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background: #323232;
  color: #e5eaee;
  font-family: 'Roboto', sans-serif;
}
.reference-item {
  display: flex;
  align-items: center;
  font-size: 16px;
}
.error {
  background: #ff0000 !important;
}
.reference-list-wrapper {
  margin: 10px 0;
  margin-bottom: 80px;
}
.provenance-table thead tr {
  font-weight: bold;
}
.flex-1 {
  flex: 1;
}
.flex-fixed {
  flex: 0 0 auto;
}
.provenance-table th,
.provenance-table td {
  padding: 4px;
  text-align: left;
}
.provenance-table .year-cell {
  text-align: right;
}
.provenance-table .wide-cell {
  width: 200px;
  text-align: right;
}
.full-height,
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.left-right-block {
  display: flex;
  justify-content: space-between;
}

.flex-box {
  display: flex;
  flex-wrap: wrap;
}

.flex-box-centered {
  display: flex;
  align-items: center;
}

.page-holder {
  display: flex;
  flex: 1;
  margin-left: 250px;
}

.text-input {
  background: #ffffff !important;
}

.loading-holder {
  left: 50%;
  position: absolute;
  margin-left: -25px;
}

.text-input label {
  transform: none !important;
  top: 0 !important;
  left: 0 !important;
  padding-left: 12px;
  font-weight: 500;
  padding-bottom: 6px;
  background: #323232;
  color: #e5eaee !important;
  width: 100%;
}

.field-spacing {
  margin-bottom: 20px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #ff0000 !important;
}

.centered {
  text-align: center;
}

.text-input input {
  font-weight: 500 !important;
  margin-top: 0 !important;
  padding: 28px 16px 0 16px !important;
}

.text-input.select {
  padding-left: 12px !important;
}

.text-input hr {
  left: 0;
  bottom: 0 !important;
  border-width: 4px !important;
}

.text-input hr:first-of-type {
  border-color: transparent !important;
}

.shadow {
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.36);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.36);
}

.shadow-bottom {
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.36);
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.square-holder {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.full {
  width: 100% !important;
}

.full .full-field {
  flex: 1 !important;
}

.align-center {
  align-items: center;
}

.item-right {
  margin-left: auto;
}

.modal-overlay {
  z-index: 9999 !important;
}

.flex-box .repeater {
  display: flex;
  align-items: center;
}

.content-wrapper {
  width: 100%;
}

.square {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
}

.page-content {
  position: relative;
  /*margin: 0 60px;*/
}

.white {
  color: #e5eaee;
}

.align-end {
  align-self: flex-end;
}

.right-controls {
  text-align: right;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.row {
  margin-bottom: 30px;
}

.light-radio {
  align-self: end;
}

.accent-check label {
  color: #ffffff !important;
}

.section {
  margin: 12px 0;
  padding: 8px 16px;
}

.dark-text {
  color: #858585;
}

h1 {
  font-weight: 500;
  color: #e5eaee;
  font-size: 18px;
  margin: 0 0 12px 0;
}

.non-fixed.collapsed td {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.non-fixed {
  background-color: transparent !important;
  border-collapse: separate !important;
}

.non-fixed tr {
  color: #e5eaee !important;
  border-bottom: 1px solid #858585 !important;
}

.non-fixed tr:hover td {
  /* background-color: #858585 !important; */
  cursor: pointer;
}

.field-wrapper {
  margin-bottom: 16px;
}

.modal {
  padding: 30px;
  background: #323232;
  color: #e5eaee;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.reference-modal table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 16px;
}
.reference-modal table td.tr-comment {
  padding-top: 0px;
}
.scrollable {
  height: 400px;
  overflow-y: scroll;
}
.field-with-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-bottom: 16px;
}
.search-icon {
  position: absolute;
  right: 10px;
}
.reference-modal table td {
  padding: 4px 10px;
}
.reference-modal table th {
  padding: 10px;
  font-weight: 400;
  text-align: left;
}
.back-arrow {
  margin-right: 12px;
}
.flex-full {
  flex: 1;
}
.ref-comments {
  margin-bottom: 10px;
}
.tr-comment {
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
}
.open-row td,
tr.open-row {
  background: #666666;
}
.modal.modal-open.reference-modal {
  width: 100%;
  max-width: 1400px;
  max-height: 100%;
  overflow: auto;
  background: #494949;
}
.reference-modal .ref-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.reference-modal .ref-tabs .tab {
  color: #bcbcbc;
  text-decoration: none;
  text-align: center;
  display: block;
  border-bottom: 3px solid #494949;
  width: 49%;
  order: -1;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.reference-modal .ref-tabs .tab:hover,
.reference-modal .ref-tabs .tab.active {
  cursor: pointer;
  color: #ffffff;
}
.reference-modal .non-fixed tr {
  border-bottom: none !important;
  height: 48px;
}
.reference-modal tr .ref-pages {
  width: 100px !important;
}
.reference-modal .non-fixed tr.even td {
  background: #363636 !important;
}
.reference-modal .non-fixed tr.edit-toggle.even:hover td,
.reference-modal .non-fixed tr.edit-toggle.odd:hover td {
  background: #2a2a2a !important;
  cursor: pointer;
}
.reference-modal .non-fixed tr.edit-open:hover td {
  cursor: default;
}
.reference-modal .non-fixed.existing-refs tr.odd:hover td {
  cursor: default;
  background: #494949 !important;
}
.reference-modal .non-fixed.existing-refs tr.even:hover td {
  cursor: default;
  background: #363636 !important;
}
.reference-modal .non-fixed.existing-refs tr.open-row:hover td {
  background: #666666 !important;
  cursor: default;
}
.img-holder {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  align-content: center;
  justify-content: center;
}

input:focus,
textarea:focus {
  transition: all 0.3s ease;
  background: #ffffff !important;
  color: #000000 !important;
}

.avatar-loader p {
  padding: 10px;
  text-align: center;
}

.dropzone {
  position: relative;
  width: 200px;
  height: 200px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.modal-overlay {
  z-index: 2;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.content-inside-margin {
  margin: 0 40px;
}

.flex-box .full-width {
  flex: 1;
}

.save-button {
  margin-top: 20px;
}
.parent-box {
  margin-bottom: 10px;
}
.margin-top {
  margin-top: 16px !important;
}

label {
  color: #e5eaee !important;
}

.field-label {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #a2a2a2 !important;
}

.auth-label {
  color: #bcbcbc !important;
}

.field-description {
  font-size: 12px;
  font-weight: 400;
  max-width: 256px;
  color: #a2a2a2;
}
.empty-list {
  margin: 10px 0;
  color: #858585;
}
.info-text {
  font-size: 12px;
  color: #858585;
}
.filter-item,
.filter-gap {
  margin: 0 8px;
}
.grid-3 {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.filter-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.flex {
  display: flex;
}
.grid-3 .section-label {
  text-align: center;
}

.font-weight-normal {
  font-weight: 400;
}

.reference-span {
  width: 400px;
}

.field-label--side {
  align-self: center;
  display: inline-flex;
  height: 48px;
  align-items: center;
}

.field-error {
  color: #FF1744;
  text-align: center;
  font-size: 12px;
}

.label--basic {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #a2a2a2 !important;
}

.centered-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-header {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 12px 0;
}

.form-controls {
  position: relative;
}

.form-controls:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #222222;
  border-bottom: 1px solid #494949;
}

.form-controls .form-controls__bottom .row {
  padding-left: 30px;
  padding-bottom: 15px;
}

.form-controls__bottom {
  padding: 0 30px;
}

.form-container {
  padding: 0 60px;
}

.form-container .reference-exerpt {
  margin-left: 16px;
}

.artworkBox {
  position: relative;
  max-width: 210px;
  margin-bottom: 30px;
}

.figcaption {
  font-size: 14px;
  line-height: 1.5;
}

.figcaption:first-of-type {
  margin-top: 6px;
}

